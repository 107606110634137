.deliveryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.479);
    height: 100vh;
    width: 100vw;
}
.deliveryModal {
    display: flex;
    flex-direction: column;
    background-color: #F1F1F1;
    border-radius: 3%;
    padding: 20px;
}

.deliveryInfo {
    text-align: center;
}
.deliveryInfo p {
    font-size: 1.2rem;
    margin: 5px;
}

@media (max-width: 1150px) {
    .deliveryContainer {    
        min-height: 90.5vh;
    }
}