* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: karla;
}
.adminContainer {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 89vh;
}
.adminOrdersContainer {
    display: flex;
}
.adminOrderCard {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 15px;
    margin: 5px;
    height: fit-content;
    width: fit-content;
}
.cartContainer {
    display: flex;
    justify-content: start;
}
.modContainer {
    display: flex;
    flex-direction: column;
}
.name {
    margin: 5px;
}
.menuItems {
    margin: 5px;
    display: flex;
    align-items: center;
}
.available {
    background: none;
    border: none;
    margin: 5px;
    cursor: pointer;
}
.available img {
    height: 20px;
}
.status {
    display: flex;
    align-items: center;
}
.green {
    margin: 10px;
    height: 30px;
    width: 30px;
    background-color: rgb(11, 202, 11);
    border-radius: 20px;
}
.red {
        margin: 10px;
        height: 30px;
        width: 30px;
        background-color: rgb(11, 202, 11);
        border-radius: 20px;
}
.status button {
    height: 30px;
}
