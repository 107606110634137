* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: karla;
}
.errorContainer {
    padding-top: 80px;
    min-height: 89vh;
}

.errorContainer h1 h2 {
    padding: 80px;
    text-align: center;
}