* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: karla;
}
.orderDetailContainer {
    padding-top: 90px;
    min-height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.detailInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
}