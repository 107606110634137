.div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cardPageContainer {
    display: flex;
    min-width: 60%;
    
    padding: 10px;
    cursor: pointer;
    position: relative;
}
.cardPageContainer img {
    width: 160px;
    object-fit: contain;
}
.cardPageContainer h2 {
    padding-bottom: 8px;
    width: 200px;
}
.cardPageContainer .textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    padding-left: 10px;
}
.cardPageContainer #description {
    width: 500px;
}
.cardPageContainer .textContainer p {
    text-align: center;
    margin: 10px;
}
.soldOut {
    z-index: 2;
    position: absolute;
    font-size: 50px;
    width: 100%;
    height: 100%;
    color: red;
    text-align: center;
}
@media (max-width: 767px) {
    .cardPageContainer {
        display: flex;
        flex-direction: row-reverse;
        height: auto;
        min-width: 396px;
        align-items: end;
    }
    .cardPageContainer .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 297px;
        height: auto;

    }
    .cardPageContainer img {
        width: 100px;
        align-self: center;
    }
    .cardPageContainer h2 {
        font-size: 18px;
        padding-bottom: 1px;
    }
    .cardPageContainer #description {
        margin-bottom: -2px;
        width: auto;
        text-align: start;
    }
    .cardPageContainer .textContainer p {
        text-align: center;
        margin: 5px 5px 5px 0px;
    }
    .btns {
        margin: 0;
    }
    .addToCart {
        font-size: 0.7rem;
        height: auto;
    }
}