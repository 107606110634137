.dropUp {
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    padding: 10px;
    box-shadow: 0.5px 0.5px black;
    transform: translateX(150%) translateY(-55%);
}
.formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}
.modsLabel {
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}
.modsLabel:hover {
    background-color: #65071b;
    transition: 0.3s;
}
.checkboxs {
    height: 20px;
    width: 40px;
    cursor: pointer;
}
.x {
    background: none;
    border: 0;
    border-radius: 10px;
    color: black;
    align-self: flex-end;
    cursor: pointer;
}
@media (max-width: 767px) {
    .dropUp {
        width: 200px;
        transform: translateY(-8%);
    }
}