.rewardsContainer {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 89vh;
}
.displayInfo {
    text-align: center;
    padding: 10px;
}
.displayInfo h1 {
    color: black;
    font-size: 5rem;
}
.displayInfo h2 {
    color: #65071b;
    font-size: 3rem;
}
.rewardsContainer p {
    padding: 0px 5px 0px 5px;
    font-size: 1.5rem;
}
.rewardsContainer button {
    height: 70px;
    font-size: 1.5rem;
    background-color: #65071b;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: white;
}
.rewardsInfo li {
    font-size: 1.2rem;
}
@media (max-width: 924px) {
    .rewardsInfo li {
        font-size: 0.8rem;
        margin-left: 5px;
    }
    .container {
        width: 428px;
    }
}