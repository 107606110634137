.cartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
    min-height: 87vh;
    position: relative;
}
#h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}
ul{
    margin: 10px;
}
.total {
    text-align: end;
}
.cardPageContainer {
    width: 324px;
}
.reward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.reward input {
    width: 20px;
    margin-left: 3px;
}

.reward {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
#reward {
    color: green;
}

@media (max-width: 1150px) {
    .cartContainer {    
        min-height: 100vh;
    }
}
