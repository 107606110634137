* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: karla;
}
.banner {
background: url('/src/assets/pataconCA.jpg');
background-size: contain;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80vh;
}
.homeContainer h1 {
    font-family: "Madimi One";
    text-align: center;
    color: white;
    font-size: 4rem;
    text-shadow: 1px 1px 2px black;
}
.cta {
    height: 5vh;
    width: auto;
    padding: 10px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.452);
    color: white;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
}
.menu{
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin: 3% 10% 1% 10%;
}
.menu h2 {
    text-align: center;
}
.menu .menucards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.cards {
    height: 300px;
    width: 400px;
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}
.entries {
    background-size: cover;
    flex-grow: 1;
}
.hamburguers {
    background: url('/src/assets/burguerCA.jpg');
    background-size: cover;
}
.patacon {
    background: url('/src/assets/pataconP.jpg');
    background-size: cover;
    flex-grow: 0.3;
}
.arepas {
    background: url('/src/assets/areponCA.jpg');
    background-size: cover;
    flex-grow: 0.8;
}
.bread {
    background: url('/src/assets/sub.jpg');
    background-size: cover;
    flex-grow: 0.5;
}
.cardInfo {
    color: white;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin: 30px;
}
.cardInfo h2 {
    text-align: left;
}

@media (max-width: 1150px) {
    .banner {
        background-size: cover;
    }
    .menu {
        align-items: center;
    }
    .menucards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}