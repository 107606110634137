.footer {
    display: flex;
    justify-content: space-around;
    padding: 30px;
    background: #65071b;
    color: white;
    text-align: center;
}
p {
    cursor: pointer;
}